<template>
  <panel
    :breadcrumbs="breadcrumbs"
  >
    <v-stepper
      v-model="tab"
    >
      <v-alert
        :value="true"
        type="info"
      >
        This feature is used for suggestion purposes only. To report an issue, please contact our Dealer Management Team (DMT)
        or Customer Service accordingly.
      </v-alert>
      <v-stepper-header>
        <v-stepper-step
          key="1-step"
          :complete="target !== null"
          :step="1"
          :editable="!canSendSuggestion"
        >
          Category
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          key="2-step"
          :complete="description !== null && description.length > 0"
          :step="2"
          :editable="!canSendSuggestion"
        >
          Details & Attachments
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          key="3-step"
          :complete="alertSuccess"
          :step="3"
          :editable="!canSendSuggestion"
        >
          Submission
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          key="1-content"
          :step="1"
        >
          <v-card
            class="mb-5 pa-3"
          >
            <v-card-title>
              Select the category on which you want to make a suggestion:
            </v-card-title>
            <v-card-text>
              <v-radio-group
                v-model="target"
                :mandatory="false"
              >
                <v-radio
                  v-for="(category, n) in categories"
                  :key="n"
                  :label="category.label"
                  :value="category.value"
                />
              </v-radio-group>
            </v-card-text>
          </v-card>

          <v-btn
            color="primary"
            @click="nextStep(1)"
          >
            Continue
          </v-btn>
        </v-stepper-content>

        <v-stepper-content
          key="2-content"
          :step="2"
        >
          <v-card
            class="mb-5 pa-3"
          >
            <v-card-text>
              <div>Details of suggestion (optional):</div><br>
              <v-text-field
                v-model="subject"
                label="Subject"
                outline
              />
              <v-textarea
                v-model="description"
                label="Text"
                outline
              />
              <div>Attach medias or supporting documents (optional):</div><br>
              <!--v-checkbox
                v-model="upload"
                label="Attach medias or supporting documents:"
              /-->
              <dashboard
                :uppy="uppy"
                :props="dashboardProps"
              />
            </v-card-text>
          </v-card>

          <v-btn
            color="primary"
            @click="nextStep(2)"
          >
            Continue
          </v-btn>
        </v-stepper-content>

        <v-stepper-content
          key="4-content"
          :step="3"
        >
          <v-card
            class="mb-5 pa-3"
          >
            <v-card-title>
              A copy of your suggestion will be sent to following email address. We will also reply your suggestion to this address:
            </v-card-title>
            <blockquote class="blockquote">
              <v-text-field
                v-model="email"
                label="Your email address"
                box
              />
            </blockquote>
            <v-divider />
            <v-card-title>
              Category:
            </v-card-title>
            <blockquote class="blockquote">
              {{ categories && categories.length > 0 ? categories.find(category => category.value === target).label : '' }}
            </blockquote>
            <v-divider />
            <v-card-title>
              Subject:
            </v-card-title>
            <blockquote class="blockquote">
              {{ subject }}
            </blockquote>
            <v-divider />
            <v-card-title>
              Text:
            </v-card-title>
            <blockquote class="blockquote">
              {{ description }}
            </blockquote>
            <v-divider />
            <v-card-title>
              Attachments:
            </v-card-title>
            <blockquote
              v-if="upload && files.length > 0"
              class="blockquote"
              v-html="attachmentList"
            />
            <v-progress-linear
              v-show="loading"
              :indeterminate="true"
            />
            <v-alert
              v-model="alertSuccess"
              type="success"
              icon="check_circle"
            >
              Your suggestion has been successfully submitted! Thank you for your time.
            </v-alert>
            <v-alert
              v-model="alertFailure"
              type="error"
              v-html="submitError"
            />
          </v-card>

          <v-btn
            v-show="!canSendSuggestion || loading"
            :loading="loading"
            :disabled="loading || ((!description || description.length === 0) && files.length === 0)"
            color="primary"
            @click="submit"
          >
            {{ submitText }}
            <span
              slot="loader"
              class="custom-loader"
            >
              <v-icon light>cached</v-icon>
            </span>
          </v-btn>

          <v-btn
            v-show="!canSendSuggestion || loading"
            :disabled="loading"
            flat
            @click="cancel"
          >
            Cancel
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </panel>
</template>

<script>
import { createGetParams, createPostParams } from '@/rest'
import { DateTime } from 'luxon'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { Dashboard } from '@uppy/vue'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

const title = 'Suggestion Box'
const debugLogger = {
  debug: (...args) => {}, // console.debug(`[Uppy] [${getTimeStamp()}]`, ...args),
  warn: (...args) => console.warn(`[Uppy] [${getTimeStamp()}]`, ...args),
  error: (...args) => console.error(`[Uppy] [${getTimeStamp()}]`, ...args),
}

function getTimeStamp () {
  return DateTime.local().setZone('Asia/Kuala_Lumpur').toLocaleString(DateTime.TIME_24_WITH_SECONDS)
}

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
    Dashboard,
  },
  data () {
    return {
      tab: 1,
      description: null,
      upload: true,
      steps: 3,
      target: null,
      failed: [],
      successful: [],
      alertFailure: false,
      alertSuccess: false,
      loading: false,
      canSendSuggestion: false,
      files: [],
      email: null,
      name: null,
      subject: null,
      apiError: null,
      categories: [],
      breadcrumbs: [
        {
          text: title, disabled: true,
        },
      ],
    }
  },

  computed: {
    uppy: function () {
      const uppy = new Uppy({
        id: 'suggestionbox',
        autoProceed: false,
        allowMultipleUploadBatches: false,
        logger: debugLogger,
        restrictions: {
          maxFileSize: null,
          minFileSize: null,
          maxTotalFileSize: 1024 * 1024 * 4,
          maxNumberOfFiles: 5,
          minNumberOfFiles: null,
          allowedFileTypes: ['application/pdf', 'video/*', 'image/*', '.jpg', '.jpeg', '.png', '.gif'],
          requiredMetaFields: [],
        },
        meta: {},
        onBeforeFileAdded: (currentFile, files) => {},
        onBeforeUpload: (files) => {},
        locale: {},
        infoTimeout: 5000,
      }).use(Tus, {
        endpoint: 'https://onesys.onexox.my/files/',
        retryDelays: [0, 1000, 3000, 5000],
      })

      uppy.on('file-added', () => {
        this.files = this.uppy.getFiles()
      })

      uppy.on('files-added', () => {
        this.files = this.uppy.getFiles()
      })

      uppy.on('file-removed', (file, reason) => {
        this.files = this.uppy.getFiles()
        if (!this.files.length) {
          this.reset()
        }
      })

      return uppy
    },
    dashboardProps: function () {
      return {
        id: 'Dashboard',
        target: 'body',
        metaFields: [],
        trigger: null,
        inline: true,
        width: 750,
        height: 500,
        thumbnailWidth: 280,
        showLinkToFileUploadResult: false,
        showProgressDetails: false,
        hideUploadButton: true,
        hideRetryButton: true,
        hidePauseResumeButton: true,
        hideCancelButton: true,
        hideProgressAfterFinish: false,
        doneButtonHandler: () => {
          this.uppy.cancelAll()
        },
        note: 'PDF, images and video only, up to 5 files with a total size of 4 MB',
        closeModalOnClickOutside: false,
        closeAfterFinish: false,
        disableStatusBar: true,
        disableInformer: false,
        disableThumbnailGenerator: false,
        disablePageScrollWhenModalOpen: true,
        animateOpenClose: true,
        fileManagerSelectionType: 'files',
        proudlyDisplayPoweredByUppy: false,
        onRequestCloseModal: () => {},
        showSelectedFiles: true,
        showRemoveButtonAfterComplete: false,
        showNativePhotoCameraButton: false,
        showNativeVideoCameraButton: false,
        browserBackButtonClose: false,
        theme: 'dark',
        autoOpenFileEditor: false,
        disableLocalFiles: false,
        disabled: !this.upload,
      }
    },
    submitText: function () {
      return this.alertFailure ? 'Re-submit' : 'Submit'
    },
    submitError: function () {
      let error = 'Error submitting your suggestion!'

      if (!this.failed.length) {
        error += '<br>' + this.apiError
      } else {
        error += '<ul>'
        this.failed.forEach((file) => {
          error += '<li>[' + file.name + ']: ' + file.error + '</li>'
        })
        error += '</ul>'
      }
      return error
    },
    attachmentList: function () {
      let html = '<ul>'

      this.files.forEach((file) => {
        html += '<li>' + file.name + '</li>'
      })
      html += '</ul>'
      return html
    },
  },

  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  },

  mounted () {
    const params = createGetParams({
      profiling: true,
    })

    this.$rest.get('getProfile.php', params)
      .then(response => {
        this.email = response.data.email
        this.name = response.data.name
      })

    this.$rest.get('getSuggestionCategories.php', params)
      .then(response => {
        this.categories = response.data
        this.target = this.categories.at(0).value
      })
  },

  watch: {
    canSendSuggestion (val) {
      if (!val) return

      this.sendSuggestion()
    },
    steps (val) {
      if (this.tab > val) {
        this.tab = val
      }
    },
  },

  methods: {
    async sendSuggestion () {
      const params = createPostParams({
        action: 'sendSuggestion',
        payload: {
          target: this.target,
          description: this.description,
          email: this.email,
          name: this.name,
          subject: this.subject,
          files: this.upload ? this.successful : [],
        },
      })
      this.loading = true
      this.alertFailure = false
      this.alertSuccess = false
      try {
        await this.$rest.post('postAction.php', params)
        this.alertSuccess = true
        this.alertFailure = false
      } catch (e) {
        this.apiError = e.message + (e.response ? ': ' + e.response.data : '')
        this.alertFailure = true
        this.alertSuccess = false
        this.canSendSuggestion = false
      }
      this.loading = false
    },
    submit () {
      const failed = this.failed.length
      const files = this.uppy.getFiles()

      this.loading = true
      this.alertFailure = true
      this.alertSuccess = false
      if (!this.apiError) this.reset()

      if (this.upload && files.length > 0 && !this.apiError) {
        if (failed) {
          this.uppy.retryAll().then((result) => {
            this.successful = result.successful
            this.failed = result.failed
            // this.alertSuccess = this.successful.length && !this.failed.length
            this.alertFailure = this.failed.length
            this.loading = false
            this.canSendSuggestion = !this.failed.length
          })
        } else {
          this.uppy.upload().then((result) => {
            this.successful = result.successful
            this.failed = result.failed
            // this.alertSuccess = this.successful.length && !this.failed.length
            this.alertFailure = this.failed.length
            this.loading = false
            this.canSendSuggestion = !this.failed.length
          })
        }
      } else {
        this.loading = false
        this.apiError = null
        this.canSendSuggestion = true
      }
    },
    reset () {
      this.apiError = null
      this.alertFailure = false
      this.alertSuccess = false
      this.successful = []
      this.failed = []
    },
    cancel () {
      this.reset()
      this.uppy.cancelAll()
      this.upload = false
      this.description = null
      this.tab = 1
    },
    nextStep (n) {
      if (n === this.steps) {
        this.tab = 1
      } else {
        this.tab = n + 1
      }
    },
  },

}
</script>

<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
